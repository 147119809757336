import whenAvailable from 'Utils/whenAvailable.js';

/* eslint-disable new-cap */

const appId = 'h0kayiwv';

const loader = function () {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = 'https://widget.intercom.io/widget/h0kayiwv';
	const target = document.querySelectorAll('script')[0];
	target.parentNode.insertBefore(script, target);

	/* Make all hidden launch elements visible */
	const hiddenLaunchers = document.querySelectorAll('.launch-intercom.is-hidden');
	for (const hiddenLauncher of hiddenLaunchers) {
		hiddenLauncher.classList.remove('is-hidden');
	}
};

const init = function () {
	window.intercomSettings = {
		app_id: appId,
		custom_launcher_selector: '.launch-intercom',
	};

	/* Add Ruler ID if exists */
	whenAvailable('RulerAnalyticsVisitorId')
		.then(() => {
			window.intercomSettings.RulerId = window.RulerAnalyticsVisitorId;
			window.Intercom('update', window.intercomSettings);
		});

	(function () {
		const ic = window.Intercom;

		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', window.intercomSettings);
		} else {
			const i = function () {
				i.c(arguments);
			};

			i.q = [];

			i.c = function (args) {
				i.q.push(args);
			};

			window.Intercom = i;

			if (window.attachEvent) {
				window.attachEvent('onload', loader);
			} else {
				window.addEventListener('load', loader, false);
			}
		}
	})();
};

document.addEventListener('DOMContentLoaded', () => {
	init();
});

/* eslint-enable new-cap */
